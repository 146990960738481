import { WasedafesShopRegistrationForm } from "components/WasedafesShopRegistrationForm";
import { getShopUsageCount } from "hooks/firebase/context/wasedafes2024";
import { Link } from "react-router-dom";

export const ShopRegistrationPage = () => {
  const handleGetShopUsageCount = async () => {
    const shopUsage = await getShopUsageCount();
    console.log(shopUsage);
  };
  return (
    <div className="container mx-auto py-8">
      <h1 className="text-2xl font-bold text-center mb-8">ショップ登録</h1>
      <WasedafesShopRegistrationForm />
      <div className="text-center mt-8">
        <button onClick={handleGetShopUsageCount}>店舗使用回数取得</button>
      </div>
    </div>
  );
};
