import { useState, FormEvent } from "react";
import { addShop } from "../hooks/firebase/context/wasedafes2024";

export const WasedafesShopRegistrationForm = () => {
  const [shopName, setShopName] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [circleName, setCircleName] = useState("");
  const [generalManagerName, setGeneralManagerName] = useState("");
  const [photo, setPhoto] = useState<File | null>(null);
  const [mapPhoto, setMapPhoto] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      if (!photo) {
        throw new Error("写真を選択してください");
      }
      if (!mapPhoto) {
        throw new Error("マップ写真を選択してください");
      }

      const errMsg = await addShop(
        shopName,
        contactAddress,
        photo,
        circleName,
        generalManagerName,
        mapPhoto
      );

      if (errMsg) {
        setError(errMsg);
      } else {
        // 成功時の処理
        alert("ショップを登録しました");
        // フォームをリセット
        setShopName("");
        setContactAddress("");
        setCircleName("");
        setGeneralManagerName("");
        setPhoto(null);
        setMapPhoto(null);
      }
    } catch (err) {
      setError("登録中にエラーが発生しました");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            ショップ名
          </label>
          <input
            type="text"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            サークル名
          </label>
          <input
            type="text"
            value={circleName}
            onChange={(e) => setCircleName(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            連絡先メールアドレス
          </label>
          <input
            type="email"
            value={contactAddress}
            onChange={(e) => setContactAddress(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            責任者名
          </label>
          <input
            type="text"
            value={generalManagerName}
            onChange={(e) => setGeneralManagerName(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            マップ写真
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setMapPhoto(e.target.files?.[0] || null)}
            required
            className="mt-1 block w-full"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            ショップ写真
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setPhoto(e.target.files?.[0] || null)}
            required
            className="mt-1 block w-full"
          />
        </div>

        {error && <div className="text-red-600 text-sm">{error}</div>}

        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 disabled:bg-gray-400"
        >
          {isLoading ? "登録中..." : "ショップを登録"}
        </button>
      </div>
    </form>
  );
};
